
import { PropType } from 'vue'
import { Block } from '~/domain/interfaces/BlockTypes'
import AppActions from '@constants/AppActions'
import { defineComponent } from 'vue'
import CommonSalesDialog from '~/components/common/CommonSalesDialog.vue'
import CommonVideoPlayerDialog from '~/components/common/CommonVideoPlayerDialog.vue'
import { trackLinkedinAds } from '~/domain/helpers/helpers'
declare global {
  interface Window {
    YT: any
    onYouTubeIframeAPIReady: () => void
  }
}
export default defineComponent({
  name: 'HomepageHeroNew',
  components: { CommonVideoPlayerDialog, CommonSalesDialog },
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
      salesDialogId: this.block.salesDialogId,
      salesDialogType: this.block.salesDialogType,
      isPaused: true,
      showThumbnail: true,
      youtubeEmbedUrl: this.block?.video?.link,
      currentVideoUrl: '',
      isDialogOpen: false,
      isPreviewMode: true,
      previewDuration: 5,
      youtubePlayer: null as any,
      isYouTubeApiReady: false,
      youtubeLoopInterval: null as any,
      isVideoModalClosed: false,
      youtubePosterImage: this.block?.video?.poster,
    }
  },
  mounted() {
    if (this.isYoutubeLink) {
      this.loadYouTubeApi()
    } else {
      this.$nextTick(() => {
        this.initializeVideoPreview()
      })
    }
  },
  computed: {
    videoPoster() {
      return this.block?.video?.thumbnail?.url || ''
    },
    heroButtonLink() {
      let link = this.block.newDesignButton
      if (!this.userIsAuthenticated) {
        link = this.block.newDesignButton + '/register'
      }
      return link
    },
    userIsAuthenticated() {
      return this.$store.getters['userIsAuthenticated']
    },
    isYoutubeLink(): boolean {
      return this.block?.video?.link.includes('https://www.youtube.com')
    },
  },
  methods: {
    async heroButtonClicked() {
      if (!this.userIsAuthenticated) {
        await this.$store.dispatch(AppActions.TRACK_SIGN_UP_EVENT, {
          context: this,
          location: 'home',
          link: this.heroButtonLink || null,
        })
      } else {
        await this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
          context: this,
          params: {
            name: 'Log in - Log in intent',
            category: 'Log in',
            action: 'Log in',
            label: 'Log in intent',
            misc: [
              {
                type: 'location',
                value: 'hero shot',
              },
            ],
          },
        })
      }
    },
    salesButtonClicked() {
      trackLinkedinAds('book-demo')
      this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
        context: this,
        params: {
          name: 'Demo - Request Demo',
          category: 'Goal completion',
          action: 'Request Demo',
          label: 'homepage_hero',
          misc: [{ type: 'location', value: 'homepage_hero_section' }],
        },
      })

      // @ts-ignore
      this.$refs.salesDialogRef?.talkToSalesClicked()
    },
    loadYouTubeApi() {
      if (!window.YT) {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)
        window.onYouTubeIframeAPIReady = () => {
          this.isYouTubeApiReady = true
          this.createYouTubePlayer()
        }
      } else {
        this.isYouTubeApiReady = true
        this.createYouTubePlayer()
      }
    },

    createYouTubePlayer() {
      if (!this.isYouTubeApiReady) {
        return
      }
      const videoId = this.getYouTubeVideoId(this.block?.video?.link)
      this.youtubePlayer = new window.YT.Player('youtube-player', {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          autoplay: 0,
          mute: 1,
          controls: 0,
          showinfo: 0,
          rel: 0,
          loop: 0,
          start: 0,
          playsinline: 1,
          enablejsapi: 1,
          origin: window.location.origin,
          widget_referrer: window.location.href,
        },
        events: {
          onReady: this.onYouTubePlayerReady,
          onStateChange: this.onYouTubePlayerStateChange,
        },
      })
    },

    onYouTubePlayerReady(event: any) {
      event.target.mute()
      event.target.playVideo()
      this.startYouTubeLoop()
    },

    onYouTubePlayerStateChange(event: any) {
      if (event.data === window.YT.PlayerState.PLAYING) {
        this.startYouTubeLoop()
      } else if (event.data === window.YT.PlayerState.ENDED) {
        this.youtubePlayer.seekTo(0)
        this.youtubePlayer.playVideo()
      }
    },

    startYouTubeLoop() {
      if (this.youtubeLoopInterval) {
        clearInterval(this.youtubeLoopInterval)
      }
      this.youtubeLoopInterval = setInterval(() => {
        if (this.youtubePlayer && this.youtubePlayer.getCurrentTime) {
          const currentTime = this.youtubePlayer.getCurrentTime()
          if (currentTime >= this.previewDuration) {
            this.youtubePlayer.seekTo(0)
          }
        }
      }, 100)
    },

    getYouTubeVideoId(url: string): string {
      if (!url) return ''
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const match = url.match(regExp)
      return match && match[2].length === 11 ? match[2] : ''
    },

    initializeVideoPreview() {
      const videoPlayer = this.$refs.videoPlayer as HTMLVideoElement
      if (videoPlayer && this.isPreviewMode) {
        videoPlayer.muted = true
        videoPlayer.currentTime = 0

        videoPlayer
          .play()
          .then(() => {
            this.setupPreviewLoop(videoPlayer)
          })
          .catch((error) => {
            console.error('Error playing video:', error)
          })
      }
    },

    setupPreviewLoop(videoPlayer: HTMLVideoElement) {
      const resetTime = () => {
        if (videoPlayer.currentTime >= this.previewDuration) {
          videoPlayer.currentTime = 0
        }
      }

      videoPlayer.addEventListener('timeupdate', resetTime)

      this.$once('hook:beforeDestroy', () => {
        videoPlayer.removeEventListener('timeupdate', resetTime)
      })
    },

    handleVideoClick() {
      if (this.isPreviewMode || this.isVideoModalClosed) {
        this.isPreviewMode = false
        this.currentVideoUrl = this.block?.video?.link
        this.isDialogOpen = true
        this.isVideoModalClosed = false

        if (this.isYoutubeLink && this.youtubePlayer) {
          clearInterval(this.youtubeLoopInterval)
          this.youtubePlayer.stopVideo()
        } else {
          const videoPlayer = this.$refs.videoPlayer as HTMLVideoElement
          if (videoPlayer) {
            videoPlayer.pause()
          }
        }
      }
    },

    handleVideoModalClose() {
      this.isDialogOpen = false
      this.isVideoModalClosed = true
      this.resetVideoPreview()
    },

    resetVideoPreview() {
      this.isPreviewMode = true
      if (this.isYoutubeLink) {
        if (this.youtubePlayer) {
          this.youtubePlayer.seekTo(0)
          this.youtubePlayer.playVideo()
          this.startYouTubeLoop()
        } else {
          this.createYouTubePlayer()
        }
      } else {
        this.initializeVideoPreview()
      }
    },

    toggleVideo() {
      this.currentVideoUrl = this.block?.video?.link
      this.isDialogOpen = true
    },
  },
  beforeDestroy() {
    if (this.youtubeLoopInterval) {
      clearInterval(this.youtubeLoopInterval)
    }
  },
})
